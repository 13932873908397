<template>
  <div class="dashboard">
    <h4 class="mb-4">Campaign Summary</h4>
    <v-row class="campaign-stats">
      <v-col cols="4">
        <v-card>
          <v-card-text class="d-flex align-center">
            <div class="stat-icon d-flex align-center justify-center">
              <img
                src="@/assets/images/icon-view.svg"
                width="34"
              />
            </div>
            <div class="align-center">
              <p>Total Views</p>
              <h3>{{ summery.total_views || 0 }}</h3>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-text class="d-flex align-center">
            <div class="stat-icon d-flex align-center justify-center">
              <img
                src="@/assets/images/icon-spent.svg"
                width="29"
              />
            </div>
            <div>
              <p>Total Earnings</p>
              <h3>{{ summery.total_earnings || 0}}</h3>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-text class="d-flex align-center">
            <div class="stat-icon d-flex align-center justify-center">
              <img
                src="@/assets/images/icon-total-creators.svg"
                width="31"
              />
            </div>
            <div>
              <p>Total Campaigns</p>
              <h3>{{ totalApprovedCampaign }}</h3>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-progress-linear
      indeterminate
      color="primary"
      class="mt-4"
      v-if="loading"
    ></v-progress-linear>

    <h4
      class="mt-8"
      v-if="campaignData.length > 0"
    >My Campaigns</h4>
    <v-simple-table
      class="mb-4"
      v-if="campaignData.length > 0"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-center"
              width="50"
            >Sr.</th>
            <th
              class="text-center"
              width="300"
            >Campaign Name</th>
            <th
              class="text-center"
              width="150"
            >Channel Name</th>
            <th class="text-center">Campaign Type</th>
            <th class="text-center">Content Type</th>
            <th class="text-center">Category</th>
            <th class="text-center">Language</th>
            <th class="text-center">Gender</th>
            <th class="text-center">Budget</th>
            <!-- <th class="text-center">Campaign Status</th> -->
            <!-- <th
              class="text-center"
              width="100"
            >Add Time</th> -->
            <th class="text-center">Approval</th>
            <th class="text-center">Total Post</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in campaignData"
            :key="item.id"
          >
            <td class="text-center">{{ index + 1 }}</td>
            <td>
              <div
                class="d-flex align-center link"
                @click="campDetail(item)"
              >
                <div class="mr-2">
                  <v-icon
                    v-if=" item.promo_platform === 1"
                    color="#ff0101"
                    large
                  >mdi-youtube</v-icon>
                  <v-img
                    v-else
                    src="@/assets/images/icon-instagram.svg"
                    width="28"
                  ></v-img>
                </div>
                <div>
                  <div>{{ item.campaign_name }}</div>
                  <div class="d-flex justify-start align-center">
                    <v-icon
                      small
                      class="mr-1"
                      color="teal"
                    >mdi-briefcase-account-outline
                    </v-icon>
                    <h5>{{item.company_name || '-'}}</h5>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <a
                target="_blank"
                class="link"
                :href="item.channelLink"
              >{{item.channelName}}</a>
              <!-- <div @click.prevent="goChannel(item.channelLink)">{{item.channelName}}</div> -->
            </td>
            <td class="text-center">{{ item.campaign_type | campaignType}}</td>
            <td
              class="text-center"
              v-if="item.promo_platform === 1"
            >{{ item.content_type | youtube  }}</td>
            <td
              class="text-center"
              v-else
            >{{ item.content_type | instagram  }}</td>
            <td class="text-center">
              <span
                v-for="category in item.category"
                :key="category"
              >{{ category }}, </span>
            </td>
            <td class="text-center">
              <span
                v-for="language in item.language"
                :key="language"
              >{{ language }}, </span>
            </td>
            <td class="text-center">
              <span
                v-for="gender in item.gender"
                :key="gender"
              >{{ gender }}, </span>
            </td>
            <td class="text-center">{{ item.budget | formatNumber}}</td>
            <!-- <td class="text-center">{{ item.campaign_status | campaignStatus }}</td> -->
            <!-- <td class="text-center">{{ dateFormat(item.add_time) }}</td> -->
            <td class="text-center">
              <v-chip
                v-if="item.status === 0"
                color="warning"
                small
                outlined
              >
                <v-icon
                  small
                  left
                >mdi-alert-circle-outline</v-icon> Pending
              </v-chip>
              <v-chip
                v-if="item.status === 1"
                color="success"
                small
                outlined
              >
                <v-icon
                  small
                  left
                >mdi-check-circle-outline</v-icon> Approved
              </v-chip>
              <v-chip
                v-if="item.status === 2"
                color="error"
                small
                outlined
              >
                <v-icon
                  small
                  left
                >mdi-close-octagon-outline</v-icon> Rejected
              </v-chip>
              <v-chip
                v-if="item.status === 3"
                color="pink"
                small
                outlined
              >
                <v-icon
                  small
                  left
                >mdi-heart</v-icon> Shortlisted
              </v-chip>
            </td>
            <td class="text-center">
              <v-chip
                color="secondary"
                small
                text-color="white"
              >
                {{ (item.total_post || item.total_post === 0) ? item.total_post  : 0 }}
              </v-chip>
            </td>
            <td class="text-center">
              <div class="d-flex align-center justify-center">
                <!-- :disabled="item.status===0?false:true" -->
                <v-btn
                  icon
                  dark
                  color="#F54153"
                  v-if="item.status===0"
                  size="28"
                  @click="assigndelete(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <!-- <v-btn
                  icon
                  dark
                  v-if="item.status===1"
                  color="primary"
                  size="28"
                  @click="editPost(item.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn> -->
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      v-model="params.page"
      :length="this.totalPages"
      @input="getappliedList()"
      :total-visible="10"
      v-if="!loading && this.totalPages > 1"
    ></v-pagination>
    <div
      class="text-center ma-8"
      v-if="!loading && campaignData.length <= 0"
    >
      <img
        src="@/assets/images/no-campaign.png"
        width="150"
      />
      <h3>No Campaigns Found</h3>
      <v-btn
        depressed
        dark
        large
        color="primary"
        to="/campaign/list"
        style="width: 350px;"
      >Apply for a campaign</v-btn>
    </div>
  </div>
</template>

<script>
import { getappliedList, assignDelete } from '@/api/campaign'

export default {
  name: 'Dashboard',
  data () {
    return {
      params: {
        page: 1,
        size: 10
      },
      totalPages: 0,
      loading: false,
      campaignData: [],
      totalApprovedCampaign: 0,
      summery: []
    }
  },
  mounted () {
    this.getappliedList()
    // this.$router.beforeHooks[0]((to, from, next) => {
    // })
    // console.log(this.$router.beforeHooks[0])
  },
  methods: {
    campDetail (item) {
      this.$router.push(`/campaign/details/${item.campaign_id}/${item.status}`)
    },
    async getappliedList () {
      this.loading = true
      this.campaignData = []
      const data = await getappliedList(this.params)
      if (data.success) {
        this.campaignData = data.campaigns.map(item => {
          item.category = item.category ? item.category.split(',') : []
          item.language = item.language ? item.language.split(',') : []
          item.gender = item.gender ? item.gender.split(',') : []
          return item
        })
        const total = data.campaigns.filter(row => row.status === 1)
        this.totalApprovedCampaign = total.length
        this.summery = data.summery
        this.totalPages = Math.ceil(data.total_count / 10)
      } else {
        this.$snackbar({
          message: data.message
        })
        this.campaignData = []
      }
      this.loading = false
    },
    async assigndelete (item) {
      this.loading = true
      const data = await assignDelete(item)
      this.loading = false
      if (data.success) {
        this.$snackbar({
          message: data.message
        })
        this.getappliedList()
      } else {
        this.$snackbar({
          message: data.message,
          color: 'error',
          timeout: 5000
        })
      }
    },
    goChannel (link) {
      this.$router.push(link)
    }
  }
}
</script>

<style scoped>
  h4 {
    text-transform: uppercase;
    margin: 0;
  }
  .link:hover {
    color: #f54153 !important;
    cursor: pointer;
  }
  .link {
    color: #333333 !important;
  }
  .campaign-stats h3 {
    color: #333333;
    margin: 5px 0px 0px 0px;
  }
  .campaign-stats p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #6b6b6b;
    margin: 0;
  }
  .campaign-stats .stat-icon {
    width: 61px;
    height: 61px;
    background: rgba(245, 65, 83, 0.05);
    border-radius: 50%;
    margin: 0 30px 0 0;
  }
  .v-data-table >>> .v-data-table__wrapper > table {
    border-spacing: 0 12px !important;
  }
  .theme--light.v-data-table .v-data-table__wrapper table tAdd Pioh {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    border: none !important;
    padding: 16px 10px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    border: none !important;
    padding: 16px 10px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
</style>
